import { useState } from "react";

export default ({ defaultValue, maxLength = 30, enter = false, regex = null }) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = e => {
    const {
      target: { value }
    } = e;

    if (regex && value !== "") {
      if (!regex.test(value)) {
        return;
      }
    }

    if (value.length <= maxLength) {
      if (enter) {
        setValue(value.replace(/(\n|\r\n)/g, ""));
      } else {
        setValue(value);
      }
    }
  };

  return { value, setValue, onChange };
};
